/* start custom scss snippet */
.has-aside {
  .main {
    max-width: map-get($layout, content-max-width);
    @include media-breakpoint-down(lg) {
      max-width: map-get($layout, content-max-width);
    }
  }
}
/* end custom scss snippet */
